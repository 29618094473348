.banner-ads-container:has(.realestates-web__banner-xs) {
  margin: $andes-spacing-16 0;
}

.feed__item--banner-ads:has(.realestates-web__banner-xs) {
  margin-top: 1rem;
  padding-inline: 20px !important;
}

.hub-mobile:has(.feed__item--banner-ads) {
  &:has(.realestates-web__banner-xs-single-no-container) {
    .andes-carousel-snapped__container:first-child {
      margin-bottom: $andes-spacing-16;
    }
  }
}

.vcp-desktop {
  .banner-ads-container:has(.realestates-web__layout-container) {
    margin-bottom: $andes-spacing-16;
  }
}
