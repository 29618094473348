@import '../../../../../styles/layout';

.feed__item-vertical {
  grid-column: 1 / -1;

  .andes-carousel-snapped {
    height: 206px;
    padding: 16px 0;
    margin-top: -32px;
  }

  .andes-carousel-snapped__container--content {
    margin: 0 !important;
    width: 100% !important;

    .andes-carousel-snapped__control {
      z-index: 1 !important;
    }

    .andes-carousel-snapped__wrapper {
      height: 206px;
      gap: 16px;

      .andes-carousel-snapped__slide {
        margin-right: 0 !important;
        width: 136px !important;
        height: 204px;

        .andes-card.mediacard-vertical .poster-media-card__link img {
          transition: filter 200ms ease-out;
        }

        .andes-card.mediacard-vertical .poster-media-card__link:hover img {
          filter: drop-shadow(0 0 4px rgb(0 0 0 / 40%));
        }
      }
    }
  }
}
@media (width >= 1246px) {
  .feed__item-vertical {
    .andes-carousel-snapped {
      width: calc(100% + 11px);
      padding-left: 11px;
      margin-left: -11px;
    }
  }
}

.feed__item-live {
  grid-column: 1 / -1;
  .progress-indicator {
    position: absolute;
    bottom: 0;
    margin: 0;
  }
  .andes-carousel-snapped__container {
    margin-left: 0 !important;
    width: 100% !important;

    .andes-carousel-snapped {
      height: auto;
      padding: 16px 0;
      margin-top: -16px;
    }

    .andes-carousel-snapped__wrapper {
      height: auto;
      gap: 16px;

      .andes-carousel-snapped__slide {
        margin-right: 0 !important;
        height: auto;
        @media only screen and (min-width: $xl-breakpoint) {
          flex: 0 0 calc(25.3% - 16px);
        }
      }

      .mediacard {
        width: 100%;

        .progress-indicator .fill {
          border-radius: 0;
        }
      }

      .mediacard {
        .andes-badge__content span {
          line-height: 14px;
        }
      }
    }
  }
}
